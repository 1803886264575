export default {
  data() {
    return {
      flag3: false,
      rollbackLoading: false,
      rollbackData: {},
      rollbackRule: {
        Reason: [{ required: true, message: " " }]
      },
    }
  },
  methods: {
    open3() {
      this.flag3 = true
      this.rollbackData.Id = this.id
    },
    close3() {
      this.flag3 = false
      this.rollbackLoading = false
      this.rollbackData = {}
      this.$refs.rollbackForm.resetFields()
    },
    ok3() {
      this.$refs.rollbackForm.validate(valid => {
        if (valid) {
          this.rollbackLoading = true
          this.$axios
            .post("/Api/BtmsStartBill/Rollback", this.rollbackData)
            .then((res) => {
              this.rollbackLoading = false
              if (res.result) {
                this.$Message.success("撤销成功");
                this.$emit("on-upd")
                this.close3()
                this.req();
              } else {
                this.rollbackLoading = false
                if (res.errormsg) {
                  this.$Message.error(res.errormsg);
                }
              }
            })
            .catch((err) => {
              this.$Message.error(err.errormsg);
            });
        }
      })
    }
  }
}