<template>
  <div class="bk-page" id="startBill">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="90" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="测试项目">
            <Select :disabled="!searchData1.OrderId" v-model="searchData1.ItemId">
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns" style="width: 220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.BtmsStartBillAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import edit from "./edit.vue";
export default {
  components: { PickOrder, edit },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      orderItems: [],
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode" },
        { title: "测试项目", key: "ItemName" },
        // { title: "发起人", key: "CreateUserName" },
        {
          title: "客户",
          render: (h, params) => h("span", params.row.ClientName || "--"),
        },
        {
          title: "当前审批人",
          render: (h, params) => h("span", params.row.CurrentUserName || "--"),
        },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.startBill.StatusBadge[params.row.Status],
                text: params.row.Tips,
              },
            });
          },
        },
        // { title: "状态", key: "Tips" },
        {
          title: "操作",
          width: 100,
          align: "right",
          render: (h, params) => {
            let a = [];
            let reviewABLE =
              params.row.CurrentUserId == this.$store.state.user.Id && (params.row.Status == 1 || params.row.Status == 2);
            let editABLE = this.$able.map.BtmsStartBillUpd && params.row.Status == 1;
            if (reviewABLE) {
              a.push(
                h(
                  "span",
                  {
                    style: { cursor: "pointer", color: "#2d8cf0" },
                    on: {
                      click: () => {
                        this.$refs.edit.open(params.row.Id);
                      },
                    },
                  },
                  "审批"
                )
              );
            } else {
              a.push(
                h("Icon", {
                  props: { type: editABLE ? "md-create" : "md-open", size: 18 },
                  attrs: { title: editABLE ? "编辑" : "详情" },
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      this.$refs.edit.open(params.row.Id);
                    },
                  },
                })
              );
            }
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.BtmsStartBillDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (window.toTabData && window.toTabData.action == "startBill") {
        this.searchData1 = {
          OrderId: window.toTabData.OrderId,
          OrderCode: window.toTabData.OrderCode,
          ItemId: window.toTabData.ItemId,
        };
        this.reqItems();
        this.pageIndex = 1;
        this.onSearch();
        this.$refs.edit.open(window.toTabData.StartBillId, {
          OrderId: window.toTabData.OrderId,
          OrderCode: window.toTabData.OrderCode,
          ItemId: window.toTabData.ItemId,
        });
        window.toTabData = null;
      } else if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.reqItems();
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
    },
    initSearch() {
      this.reSearchData();
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      if (this.$able.map.BtmsStartBillQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsStartBill/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 选择委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      this.orderItems = [];
      this.searchData1.ItemId = 0;
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.searchData1.OrderId = "";
        this.$set(this.searchData1, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
      this.orderItems = [];
      this.searchData1.ItemId = 0;
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.searchData1.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // 新增
    toAdd() {
      this.$refs.edit.open();
    },
    // 删除
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsStartBill/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>

