<template>
  <div id="StartBillEdit">
    <Drawer
      :mask-closable="false"
      :title="id ? '查看' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer start-bill-drawer"
      v-model="flag"
      width="400"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form :label-width="100" :model="formd" :rules="rules" @submit.native.prevent ref="form">
          <FormItem label="状态：" v-if="id > 0">
            <div>{{ $designConst.startBill.Status[formd.Status] }}</div>
          </FormItem>
          <FormItem label="委托单：" prop="OrderId" required>
            <Button
              @click="fastPickOrder"
              ghost
              size="small"
              style="margin-bottom: 8px"
              type="primary"
              v-if="id == 0 && $store.state.order.Id && !defData.OrderId"
            >快速选择：{{ $store.state.order.Code }}</Button>
            <Input
              :disabled="defData.OrderId > 0"
              :value="formd.OrderCode"
              @on-clear="unPickOrder"
              clearable
              readonly
              v-if="id == 0"
            >
              <Button
                :disabled="defData.OrderId > 0"
                @click="toPickOrder"
                icon="ios-more"
                slot="append"
              ></Button>
            </Input>
            <div v-if="id > 0">{{ formd.OrderCode }}</div>
          </FormItem>
          <FormItem label="测试项目：" prop="ItemId" required>
            <Select
              :disabled="!formd.OrderId || defData.ItemId > 0"
              v-if="id == 0"
              v-model="formd.ItemId"
            >
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
            <div v-if="id > 0">{{ formd.ItemName }}</div>
          </FormItem>
          <FormItem label="启动说明：" prop="Description" required>
            <Input type="textarea" v-if="edit_main_able" v-model="formd.Description" />
            <div v-if="!edit_main_able">{{ formd.Description }}</div>
          </FormItem>
          <FormItem label="备注：">
            <Input type="textarea" v-if="edit_main_able" v-model="formd.Remark" />
            <div v-if="!edit_main_able">{{ formd.Remark || "无" }}</div>
          </FormItem>
          <FormItem style="text-align: right" v-if="id > 0 && edit_main_able">
            <Button :loading="posting" @click="ok" type="primary">确定</Button>
          </FormItem>
          <FormItem label="审批人员：" style="margin-bottom: 0">
            <Button @click="toPickUser" icon="md-add" size="small" v-if="id == 0">添加</Button>
          </FormItem>
          <FormItem :label-width="20">
            <Spin class="bk-loading" v-if="loading2">
              <div style="height: 40px">
                <Icon type="ios-loading"></Icon>
                <span>Loading</span>
              </div>
            </Spin>
            <div :key="i" class="sign-user" v-for="(a, i) in items">
              <span>
                <span>{{ i + 1 }}、</span>
                <span>{{ a.SignUserName }}</span>
                <span>{{ a.DeptName ? "(" + a.DeptName + ")" : "" }}</span>
                <Divider type="vertical" v-if="id > 0" />
                <span class="text-sub" v-if="a.Status == 0 && a.IsCurrent == 0">未审核</span>
                <span class="text-warning" v-if="a.Status == 0 && a.IsCurrent == 1">待审核</span>
                <span class="text-success" v-if="a.Status == 1">通过</span>
                <span class="text-error" v-if="a.Status == 2">拒绝</span>
                <Divider type="vertical" v-if="a.Status == 1 || a.Status == 2" />
                <span class="text-off">{{ a.SignTime }}</span>
                <div
                  class="text-sub text-xs"
                  style="line-height: 20px; text-indent: 2em"
                >{{ a.SignOpinion }}</div>
              </span>
              <span
                @click="callReviewer(a.Id)"
                class="text-underline"
                style="cursor: pointer; color: #2d8cf0;margin-right:10px;"
                v-if="id > 0 && a.Status == 0 && a.IsCurrent && (formd.Status==1||formd.Status==2) && a.SignUserId != $store.state.user.Id"
              >催审</span>
              <span
                @click="open2(a.Id)"
                class="text-underline"
                style="cursor: pointer; color: #2d8cf0"
                v-if="
                  id > 0 &&
                  a.Status == 0 &&
                  a.IsCurrent &&
                  a.SignUserId == $store.state.user.Id
                "
              >审批</span>
              <Button
                :disabled="i == items.length - 1"
                @click="downUser(i)"
                icon="md-arrow-round-down"
                type="text"
                v-if="id == 0"
              ></Button>
              <Button
                :disabled="i == 0"
                @click="upUser(i)"
                icon="md-arrow-round-up"
                type="text"
                v-if="id == 0"
              ></Button>
              <Button @click="delUser(i)" class="close" icon="md-close" type="text" v-if="id == 0"></Button>
            </div>
          </FormItem>
          <Alert type="warning" v-show="id == 0">提示：审批流程发起后，不再允许变更审批节点，审批人员按先后顺序进行审批</Alert>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="id == 0 || rollback_able">
        <Button @click="open3" type="error" v-if="rollback_able">撤销</Button>
        <Button :loading="posting" @click="ok" type="primary" v-if="id==0">确定</Button>
      </div>
    </Drawer>

    <Modal @on-cancel="close2" title="审批" v-model="flag2" width="400">
      <Form :model="reviewData" :rules="reviewRule" @submit.native.prevent ref="reviewForm">
        <FormItem prop="Opinion" style="margin-bottom: 0">
          <Input :rows="3" placeholder="请输入审批意见" type="textarea" v-model="reviewData.Opinion" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button
          :disabled="reviewLoading && reviewData.Status != 2"
          :loading="reviewLoading && reviewData.Status == 2"
          @click="ok2(2)"
          type="error"
        >拒绝</Button>
        <Button
          :disabled="reviewLoading && reviewData.Status != 1"
          :loading="reviewLoading && reviewData.Status == 1"
          @click="ok2(1)"
          type="success"
        >通过</Button>
      </div>
    </Modal>

    <Modal @on-cancel="close3" title="撤销" v-model="flag3" width="400">
      <Form :model="rollbackData" :rules="rollbackRule" @submit.native.prevent ref="rollbackForm">
        <FormItem prop="Opinion" style="margin-bottom: 0">
          <Input :rows="3" placeholder="请输入撤销原因" type="textarea" v-model="rollbackData.Reason" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="rollbackLoading" @click="ok3" type="primary">确定</Button>
      </div>
    </Modal>

    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user @on-ok="onPickUser" multiple ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
import Review from "./review";
import Rollback from "./rollback";
export default {
  mixins: [Review, Rollback],
  components: { PickOrder, PickUser },
  data() {
    return {
      IsCreator: false, // 本人是发布者
      edit_main_able: false,
      rollback_able: false,
      // ——————————
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      defData: {},
      orderItems: [],
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        ItemId: [{ required: true, type: "number", message: " " }],
        Description: [{ required: true, message: " " }],
      },
      // ——————————
      loading2: false,
      items: [],
      items_: {},
    };
  },
  methods: {
    open(id, def) {
      if (id) {
        this.id = id;
        this.req();
        this.reqNodes();
      } else {
        this.IsCreator = true;
        this.edit_main_able = true;
        this.rollback_able = false;
        this.formd.Status = 1;
        if (def) {
          this.clear();
          this.defData = def;
          this.reqItems();
          this.formd.OrderId = def.OrderId;
          this.formd.OrderCode = def.OrderCode;
          this.formd.ItemId = def.ItemId;
        }
      }
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.IsCreator = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.defData = {};
      this.formd = {};
      this.$refs.form.resetFields();
      this.loading2 = false;
      this.items = [];
      this.items_ = {};
      this.orderItems = [];
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsStartBill/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.IsCreator =
              this.$store.state.user.Id == this.formd.CreateUserId;
            this.edit_main_able =
              this.$able.map.BtmsStartBillUpd &&
              [0, 1, 2].indexOf(this.formd.Status) > -1;
            this.rollback_able =
              this.IsCreator &&
              this.$able.map.BtmsStartBillUpd &&
              [0, 1, 2].indexOf(this.formd.Status) > -1;
            if (this.formd.OrderId) {
              this.reqItems();
            }
            // this.items = res.data.Items;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    postData() {
      let api, param;
      if (this.id > 0) {
        api = "/Api/BtmsStartBill/Update";
        param = this.formd;
      } else {
        api = "/Api/BtmsStartBill/AddEx";
        for (let i in this.items) {
          this.items[i].Sequence = i * 1 + 1;
        }
        param = {
          Main: this.formd,
          Items: this.items,
        };
      }
      this.posting = true;
      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    fastPickOrder() {
      this.formd.OrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
      this.reqItems();
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      this.orderItems = [];
      this.formd.ItemId = 0;
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.orderItems = [];
      this.formd.ItemId = 0;
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // 选择人员
    toPickUser() {
      this.$refs.PickUser.open();
    },
    onPickUser(lst) {
      console.log(lst);
      for (let a of lst) {
        if (this.items_[a.Id] == undefined) {
          this.items_[a.Id] = 1;
          this.items.push({
            SignUserId: a.Id,
            SignUserName: a.UserName,
            DeptName: a.DeptName,
          });
        }
      }
    },
    upUser(i) {
      let aa = this.items.splice(i, 1);
      this.items.splice(i - 1, 0, aa[0]);
    },
    downUser(i) {
      let aa = this.items.splice(i, 1);
      this.items.splice(i + 1, 0, aa[0]);
    },
    delUser(i) {
      this.items.splice(i, 1);
    },
    reqNodes() {
      this.loading2 = true;
      this.$axios
        .get("/Api/BtmsStartBill/QueryNodesByBill", {
          params: { BillId: this.id },
        })
        .then((res) => {
          this.loading2 = false;
          if (res.result) {
            this.items = res.data;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading2 = false;
          this.$Message.error(err.errormsg);
        });
    },
    callReviewer(id) {
      this.$axios
        .post("/Api/BtmsStartBill/CallReviewer", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("催审成功");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.start-bill-drawer {
  .ivu-form-item {
    margin-bottom: 8px;
  }
  .sign-user {
    display: flex;
    border-bottom: 1px dashed #dcded2;
  }
  .sign-user > span:first-child {
    flex: 1;
    display: inline-block;
    margin-right: 10px;
  }
}
</style>