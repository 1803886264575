export default {
  data() {
    return {
      flag2: false,
      reviewLoading: false,
      reviewData: {},
      reviewRule: {
        Opinion: [{
          validator: (rule, value, callback) => {
            if (this.reviewData.Status == 2) {
              this.reviewData.Opinion ? callback() : callback(new Error(" "))
            } else {
              callback()
            }
          }
        }]
      },
    }
  },
  methods: {
    open2(id) {
      this.flag2 = true
      this.reviewData.Id = id
    },
    close2() {
      this.flag2 = false
      this.reviewLoading = false
      this.reviewData = {}
      this.$refs.reviewForm.resetFields()
    },
    // 审核接口都同一以1为通过，2为拒绝
    ok2(status) {
      this.reviewData.Status = status
      this.$refs.reviewForm.validate(valid => {
        if (valid) {
          if (!this.reviewData.Opinion) {
            this.reviewData.Opinion = "OK"
          }
          this.reviewLoading = true
          this.$axios
            .post("/Api/BtmsStartBill/Review", this.reviewData)
            .then((res) => {
              this.reviewLoading = false
              if (res.result) {
                this.$Message.success("审核成功");
                this.$emit("on-upd")
                this.close2()
                this.req();
                this.reqNodes();
              } else {
                if (res.errormsg) {
                  this.$Message.error(res.errormsg);
                }
              }
            })
            .catch((err) => {
              this.reviewLoading = false
              this.$Message.error(err.errormsg);
            });
        }
      })
    }
  }
}